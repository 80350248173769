import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TypeGroupService } from "src/app/services/typeGroup.service";

@Component({
  selector: "app-event-type-group-info",
  templateUrl: "./event-type-group-info.component.html",
  styleUrls: ["./event-type-group-info.component.scss"],
})
export class EventTypeGroupInfoComponent implements OnInit {
  @Input() event: any;
  isLoading = false;
  competitions = null;
  events: any = [];
  selectedEventIndex = 0;
  typeGroupRank: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private typeGroupService: TypeGroupService
  ) {}

  async ngOnInit() {
    await this.getTypeGroupRank();
    await this.getTypeGroupEvents();
    this.getTypeGroupInfo(this.event._id);
  }

  async getTypeGroupRank() {
    try {
      this.isLoading = true;
      this.typeGroupRank = await this.typeGroupService.getTypegroupRank();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async getTypeGroupInfo(eventId: string) {
    const categories: Array<Object> = [
      ...new Set(
        this.typeGroupRank
          .filter((x: any) => x.competition.event === eventId)
          .map((takeoff) => takeoff.competition.category)
      ),
    ];
    this.competitions = categories.map((cat: any) => ({
      [cat.name]: this.typeGroupRank.filter(
        (tk) =>
          tk.competition.event === eventId &&
          tk.competition.category._id === cat._id
      ),
    }));
  }

  getObjectEntries(obj) {
    return Object.entries(obj).map((x) => x[1][0]);
  }

  async getTypeGroupEvents() {
    this.events = await this.typeGroupService.getTypeGroupEvents(
      this.event.typeGroup._id
    );
    this.selectedEventIndex = this.events.findIndex(
      (ev) => ev._id === this.event._id
    );
  }

  onChangeEvent(event) {
    this.selectedEventIndex = event.target.value;
    this.getTypeGroupInfo(
      this.events[event.target.value]
        ? this.events[event.target.value]._id
        : null
    );
  }
}
