<div class="modal-header">
  <h4 class="modal-title">
    {{ "FINANCIAL-GENERAL.CUSTOMER.SHOW" | translate
    }}{{ user.customer ? user.customer.name : user.admin.name }}
    {{ "BALANCE" | translate }} {{ "FINANCIAL-GENERAL.SHOW-DEBTORS" | translate
    }}{{ countTotal() | json }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card-body">
    <nav ngbNav #nav="ngbNav" class="nav-tabs">
      <ng-container ngbNavItem>
        <a ngbNavLink translate>SUBSCRIPTIONS</a>
        <ng-template ngbNavContent>
          <app-financial-users-subscriptions
            [user]="user"
            [event]="event"
            (eventUsersFinancial)="eventUsersFinancial()"
            [selectedTakeoffs]="filterSelectedTakeoffs()"
            [selectedStalls]="filterSelectedStalls()"
            (payedTakeoffs)="loadPayedTakeoffs($event)"
            (payedStalls)="loadPayedStalls($event)"
            (paymentValue)="loadPaymentValue($event)"
            [userTakeoffs]="userTakeoffs"
          ></app-financial-users-subscriptions>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink translate>AWARDS</a>
        <ng-template ngbNavContent>
          <app-financial-users-awards
            [user]="user"
            [event]="event"
            (eventUsersFinancial)="eventUsersFinancial()"
          ></app-financial-users-awards>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <div class="row" *ngIf="stalls.length > 0">
    <div class="col-md-12">
      <h3>
        {{ "STALL-RESERVATION.TITLE" | translate }}
        <span *ngFor="let stallType of filterStallsTypes(userStalls)"
          >| {{ stallType }}:
          {{ filterStallsFromType(userStalls, stallType) }}</span
        >
      </h3>
    </div>
  </div>

  <div class="table-responsive" *ngIf="stalls.length > 0">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>
            <div class="form-check">
              <input
                (change)="selectAllStalls()"
                [(ngModel)]="allStallsSelected"
                class="form-check-input position-static"
                type="checkbox"
              />
            </div>
          </th>
          <th translate>ANIMAL.SINGLE</th>
          <th translate>STALL-TYPE</th>
          <th translate>VALUE</th>
          <th translate>COACH</th>
          <th translate>CUSTOMER.SINGLE</th>
          <th translate>STATUS</th>
          <th>
            <button
              class="
                btn btn-outline-primary
                mdi mdi-printer mdi-for-btn
                horse-gradient
              "
              (click)="openChooseModal(content)"
              type="button"
            ></button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isStallsLoading">
          <td colspan="5">
            <div
              class="
                lds-dual-ring lds-dual-ring-black
                d-flex
                justify-content-center
                w-100
              "
            ></div>
          </td>
        </tr>
        <tr
          *ngFor="let item of userStalls; let i = index"
          (click)="checkSelectStall(i)"
          [ngClass]="{ 'table-success': selectedStalls[i] }"
          style="cursor: pointer"
        >
          <td>
            <div class="form-check">
              <input
                [(ngModel)]="selectedStalls[i]"
                class="form-check-input position-static"
                type="checkbox"
              />
            </div>
          </td>
          <td>
            {{ item.horse ? item.horse.name : "" }}
          </td>
          <td>
            {{ item.stall.stallType }}
          </td>
          <td>{{ event.currency | currencyPipe }}{{ item.stall.price }}</td>
          <td>
            {{ item.coach }}
          </td>
          <td>
            {{ item.customer.name }}
          </td>
          <td>
            <span
              class="badge badge-success"
              *ngIf="checkPayedStalls(item._id); else elseBadge"
              translate
            >
              PAID
            </span>
            <ng-template #elseBadge>
              <span class="badge badge-danger" translate>NOTPAID</span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3 translate>SUBSCRIPTIONS</h3>
    </div>
  </div>
  <div class="table-responsive pl-3">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>
            <div class="form-check">
              <input
                (change)="selectAllTakeoffs()"
                [(ngModel)]="allTakeoffsSelected"
                class="form-check-input position-static"
                type="checkbox"
              />
            </div>
          </th>
          <th translate>CATEGORY</th>
          <th translate>COMPETITOR.SINGLE</th>
          <th translate>ANIMAL.SINGLE</th>
          <th translate>CUSTOMER.SINGLE</th>
          <th translate>SUBSCRIPTIONS-VALUE</th>
          <th translate>AWARD</th>
          <th translate>STATUS</th>
          <th>
            <button
              class="
                btn btn-outline-primary
                mdi mdi-printer mdi-for-btn
                horse-gradient
              "
              (click)="openChooseModal(content)"
              type="button"
            ></button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isTakeoffsLoading">
          <td colspan="5">
            <div
              class="
                lds-dual-ring lds-dual-ring-black
                d-flex
                justify-content-center
                w-100
              "
            ></div>
          </td>
        </tr>
        <tr
          *ngFor="let item of userTakeoffs; let i = index"
          (click)="checkSelectTakeoff(i, item)"
          [ngClass]="{ 'table-success': selectedTakeoffs[i] }"
          style="cursor: pointer"
        >
          <td style="width: 3rem">
            <div class="form-check">
              <input
                [(ngModel)]="selectedTakeoffs[i]"
                class="form-check-input position-static"
                type="checkbox"
              />
            </div>
          </td>
          <td>
            {{ item.competition.category.name | translateCategory | async }} -
            {{ item.pass }} Passadas -
            {{ item.competition.date | date: "EEEE dd/MM" | titlecase }}
          </td>
          <td>{{ item.competitor.name }}</td>
          <td>{{ item.horse ? item.horse.name : "" }}</td>
          <td>{{ item.owner.name }}</td>
          <td>{{ event.currency | currencyPipe }}{{ item.price }}</td>
          <td>{{ item.award ? "R$" + item.award : "" }}</td>
          <td>
            <span
              class="badge badge-success"
              *ngIf="checkPayedTakeoffs(item._id); else elseBadge"
              translate
            >
              PAID
            </span>
            <ng-template #elseBadge>
              <span class="badge badge-danger" translate>NOTPAID</span>
            </ng-template>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>CHOOSELIST</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body"></div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.close('landscape')"
      translate
    >
      HORIZONTAL
    </button>
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.close('portrait')"
      translate
    >
      VERTICAL
    </button>
  </div>
</ng-template>
