import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { TypeGroup } from "../dto/TypeGroup";

@Injectable({
  providedIn: "root",
})
export class TypeGroupService {
  constructor(private http: HttpClient) {}

  addTypeGroup(typeGroup: TypeGroup) {
    return this.http
      .post(`${environment.apiUrl}/typeGroup`, typeGroup)
      .toPromise();
  }

  getTypeGroups() {
    return this.http.get(`${environment.apiUrl}/typeGroups`).toPromise();
  }

  getTypeGroupBySlug(slug: number) {
    return this.http.get(`${environment.apiUrl}/typeGroup/${slug}`).toPromise();
  }

  getTypeGroupEvents(id: number) {
    return this.http
      .get(`${environment.apiUrl}/typeGroup/${id}/events`)
      .toPromise();
  }

  setTypeGroupEvents(typeGroup: number, events, ranking) {
    return this.http
      .post(`${environment.apiUrl}/typeGroup/event`, {
        typeGroup,
        events,
        ranking,
      })
      .toPromise();
  }

  setTypegroupRank(eventId: string) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/rank-typegroup`, {})
      .toPromise();
  }

  setCategoryRank(eventId: string, competition: string) {
    return this.http
      .post(
        `${environment.apiUrl}/event/${eventId}/rank-typegroup-competition`,
        {
          competition,
        }
      )
      .toPromise();
  }

  getTypegroupRank() {
    return this.http
      .get(`${environment.apiUrl}/typegroup/rank-typegroup`)
      .toPromise();
  }
}
