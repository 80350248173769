export class Event {
  name: String;
  org: String;
  logoUrl: String;
  folderUrl: String;
  ruleUrl: String;
  coverUrl: String;
  phone: String;
  email: String;
  startDate: Number;
  finalDate: Number;
  description: String;
  infos: String;
  address: String;
  timeOffset: String;
}
