<ngb-alert
  type="dark"
  *ngIf="event.isDoneRanking"
  (close)="disabledNotify = true"
  >Este evento já foi rankeado!</ngb-alert
>

<div class="row">
  <div class="col-md-3">
    <button
      class="btn btn-outline-primary"
      (click)="setRank('61638b933f39f915714b0f01')"
      [disabled]="event.isDoneRanking"
    >
      Rankear Sul
    </button>
  </div>

  <div class="col-md-3" *ngIf="event.typeGroup">
    <button
      class="btn btn-outline-primary"
      (click)="setTypegroupRank()"
      [disabled]="event.isDoneTypegroupRanking"
    >
      Rankear Grupo
    </button>
  </div>

  <div class="col-md-6 d-flex align-items-center" *ngIf="event.typeGroup">
    <div class="col-md-6">
    <select
      class="form-control"
      [(ngModel)]="selectedCompetition"
      id="competitions"
    >
    <option selected="true" disabled>Categoria...</option>
      <option
        *ngFor="let competition of filteredCompetitions"
        [ngValue]="competition"
      >
        {{ competition.category.name }}
      </option>
    </select>
  </div>
    <div class="col-md-6">
      <button
        class="btn btn-outline-primary"
        (click)="setCategoryRank()"
        [disabled]="event.isDoneRanking || selectedCompetition && selectedCompetition.isDoneCategoryRanking"
      >
       {{ selectedCompetition?.isDoneTypegroupRanking ? "Categoria já rankeada" : "Rankear Categoria"}}
      </button>
    </div>
  </div>
</div>
