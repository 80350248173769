<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper content-auth d-flex align-items-center auth">
      <div class="row w-100">
        <div class="col-lg-4 mx-auto">
          <div class="card">
            <div class="card-body">
              <h1 class="text-center">
                <img
                  src="../../../assets/images/hcs-horizontal.png"
                  style="width: 20rem"
                  alt="logo"
                />
              </h1>
              <h3 class="font-weight-light" translate>FORGOT-PASS.TITLE</h3>
              <form
                class="pt-3"
                [formGroup]="forgotForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="form-group">
                  <label for="email" translate>EMAIL</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    formControlName="email"
                    id="email"
                    placeholder="email"
                  />
                  <small *ngIf="!emailSent" translate
                    >FORGOT-PASS.EMAIL-TYPE</small
                  >
                  <div
                    *ngIf="
                      f.email.invalid &&
                      (f.email.dirty || f.email.touched) &&
                      !emailSent
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.email.errors.required"
                      class="form-text text-danger"
                      translate
                      >EMAIL-NEEDED</small
                    >
                  </div>
                </div>

                <div class="mt-3" *ngIf="!emailSent">
                  <button
                    type="submit"
                    [disabled]="submitted"
                    class="
                      btn btn-block btn-gradient-primary btn-lg
                      font-weight-medium
                      auth-form-btn
                    "
                    [translate]
                  >
                    {{
                      submitted
                        ? "FORGOT-PASS.VERIFYING"
                        : "FORGOT-PASS.SENDCODE"
                    }}
                  </button>
                </div>
              </form>
              <form
                class="pt-3"
                [formGroup]="resetForm"
                (ngSubmit)="onReset()"
                *ngIf="emailSent"
              >
                <div class="form-group">
                  <label for="code" translate>CODE</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    formControlName="code"
                    id="code"
                    placeholder="code"
                  />
                  <small translate>FORGOT-PASS.CODE-TYPE</small>
                  <div
                    *ngIf="
                      ff.code.invalid && (ff.code.dirty || ff.code.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="ff.code.errors.required"
                      class="form-text text-danger"
                      translate
                      >CODE-NEEDED</small
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="password" translate>PASS</label>
                  <input
                    type="password"
                    id="password"
                    class="form-control form-control-lg"
                    formControlName="password"
                    placeholder="Senha"
                  />
                  <div
                    *ngIf="
                      ff.password.invalid &&
                      (ff.password.dirty || ff.password.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="ff.password.errors.required"
                      class="form-text text-danger"
                      translate
                      >PASS-NEEDED</small
                    >
                    <small
                      *ngIf="ff.password.errors.minlength"
                      class="form-text text-danger"
                      translate
                      >PASS-CHAR</small
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="confirmPassword" translate>CONFIRMPASS</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    class="form-control form-control-lg"
                    formControlName="confirmPassword"
                    placeholder="Senha"
                  />
                  <div
                    *ngIf="
                      ff.confirmPassword.invalid &&
                      (ff.confirmPassword.dirty || ff.confirmPassword.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="ff.confirmPassword.errors.required"
                      class="form-text text-danger"
                      translate
                      >CONFIRMPASS-NEEDED</small
                    >
                    <small
                      *ngIf="ff.confirmPassword.errors.notEquivalent"
                      class="form-text text-danger"
                      translate
                      >CONFIRMPASS-CHAR</small
                    >
                  </div>
                </div>

                <div class="mt-3">
                  <button
                    type="submit"
                    [disabled]="submitted"
                    class="
                      btn btn-block btn-gradient-primary btn-lg
                      font-weight-medium
                      auth-form-btn
                    "
                    translate
                  >
                    {{
                      submitted
                        ? "FORGOT-PASS.VERIFYING"
                        : "FORGOT-PASS.ERIFYCODE"
                    }}
                  </button>
                </div>
              </form>
              <div class="text-center mt-4 font-weight-light">
                <a [routerLink]="['/login']" class="text-primary" translate
                  >FORGOT-PASS.GOBACKLOGIN</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
