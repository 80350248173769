import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./auth/login/login.component";
import { AuthGuard } from "./auth.guard";
import { ForgotPassComponent } from "./auth/forgot-pass/forgot-pass.component";
import { AccountComponent } from "./user/account/account.component";
import { EventAddComponent } from "./event/event-add/event-add.component";
import { EventListComponent } from "./event/event-list/event-list.component";
import { RegisterComponent } from "./auth/register/register.component";
import { EventHomeComponent } from "./event/event-home/event-home.component";
import { CategoriesComponent } from "./event/event-live/categories/categories.component";
import { EventLiveComponent } from "./event/event-live/event-live.component";
import { CompetitorAddComponent } from "./competitor/competitor-add/competitor-add.component";
import { FinancialComponent } from "./financial/financial.component";
import { TypeGroupHomeComponent } from "./typeGroup/typeGroup-home/typeGroup-home.component";
import { EventTypeGroupComponent } from "./event/event-typeGroup/event-typeGroup.component";
import { AnimalListComponent } from "./animal/animal-list/animal-list.component";
import { CompetitorListComponent } from "./competitor/competitor-list/competitor-list.component";
import { CustomerComponent } from "./customer/customer.component";
import { ActivityRegistryComponent } from "./activity-registry/activity-registry.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },

      {
        path: "events",
        children: [
          { path: "", component: EventListComponent },
          { path: "add", component: EventAddComponent },
          { path: "typeGroup", component: EventTypeGroupComponent },
        ],
      },
      {
        path: "typeGroup",
        children: [
          { path: "", redirectTo: "/events", pathMatch: "full" },
          {
            path: ":slug",
            pathMatch: "full",
            component: TypeGroupHomeComponent,
          },
        ],
      },
      {
        path: "event",
        children: [
          { path: "", redirectTo: "/events", pathMatch: "full" },
          {
            path: ":slug",
            children: [
              { path: "", redirectTo: "info", pathMatch: "full" },
              {
                path: "live",
                children: [
                  { path: "", component: EventLiveComponent },
                  { path: "categories", component: CategoriesComponent },
                ],
              },
              {
                path: ":tab",
                component: EventHomeComponent,
              },
            ],
          },
        ],
      },
      {
        path: "financial",
        children: [{ path: "", component: FinancialComponent }],
      },
      {
        path: "user",
        children: [
          { path: "", redirectTo: "/user/account", pathMatch: "full" },
          { path: "account", component: AccountComponent },
        ],
      },
      {
        path: "animal",
        component: AnimalListComponent,
      },
      {
        path: "competitor",
        component: CompetitorListComponent,
      },
      {
        path: "customer",
        component: CustomerComponent,
      },
      {
        path: "activity-register",
        component: ActivityRegistryComponent,
      },
    ],
  },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "forgot", component: ForgotPassComponent },
  { path: "**", redirectTo: "/dashboard" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
