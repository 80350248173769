import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { EventService } from "src/app/services/event.service";

import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Base64UploaderPlugin from "../../../@ckeditor/Base64Upload";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br";

@Component({
  selector: "app-event-info",
  templateUrl: "./event-info.component.html",
  styleUrls: ["./event-info.component.scss"]
})
export class EventInfoComponent implements OnInit {
  Editor = ClassicEditor;
  editorConfig = { language: "pt-br", extraPlugins: [Base64UploaderPlugin] };
  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element
    );
  }
  @Input() eventParam: any;
  @Input() permission: any;
  isDescriptionEditing: boolean = false;
  description: string;
  isInfosEditing: boolean = false;
  infos: string;

  @Output() initEventInfo: EventEmitter<any> = new EventEmitter();

  constructor(private _event: EventService) {}

  ngOnInit() {
    this.description = this.eventParam.description;
    this.infos = this.eventParam.infos;
  }

  showDescription() {
    this.isDescriptionEditing = true;
  }

  showInfos() {
    this.isInfosEditing = true;
  }

  async saveDescription() {
    await this._event.updateEventDescription(
      this.eventParam._id,
      this.description
    );
    this.isDescriptionEditing = false;
    this.initEventInfo.emit();
  }

  async saveInfos() {
    await this._event.updateEventInfo(this.eventParam._id, this.infos);
    this.isInfosEditing = false;
    this.initEventInfo.emit();
  }
}
