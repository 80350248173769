<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row w-100">
        <div class="col-lg-4 mx-auto">
          <div class="card">
            <div class="card-body">
              <h1 class="text-center">
                <img
                  src="../../../assets/images/hcs-horizontal.png"
                  style="width: 20rem"
                  alt="logo"
                />
              </h1>
              <h3 class="font-weight-light" translate>SIGNIN.TITLE</h3>
              <form
                class="pt-3"
                [formGroup]="registerForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="form-group">
                  <label for="name" translate>NAME</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Nome"
                    formControlName="name"
                  />
                  <div
                    *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)"
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.name.errors.required"
                      class="form-text text-danger"
                      translate
                      >NAME-NEEDED</small
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="email" translate>EMAIL</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    formControlName="email"
                    id="email"
                    placeholder="{{ 'EMAIL' | translate }}"
                  />

                  <div
                    *ngIf="
                      f.email.invalid && (f.email.dirty || f.email.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.email.errors.required"
                      class="form-text text-danger"
                      translate
                      >EMAIL-NEEDED</small
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="cpforcnpj" translate>CPFCNPJ</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    formControlName="cpforcnpj"
                    placeholder="{{ 'CPFCNPJ' | translate }}"
                  />
                  <div
                    *ngIf="
                      f.cpforcnpj.invalid &&
                      (f.cpforcnpj.dirty || f.cpforcnpj.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.cpforcnpj.errors.required"
                      class="form-text text-danger"
                      translate
                      >CPFCNPJ-NEEDED</small
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label for="phone" translate>PHONE</label>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    formControlName="phone"
                    placeholder="{{ 'PHONE' | translate }}"
                  />
                  <div
                    *ngIf="
                      f.phone.invalid && (f.phone.dirty || f.phone.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.phone.errors.required"
                      class="form-text text-danger"
                      translate
                      >PHONE-NEEDED</small
                    >
                    <small
                      *ngIf="f.phone.errors.minlength"
                      class="form-text text-danger"
                      translate
                      >PHONE-CHAR</small
                    >
                  </div>
                </div>
                <!--<div class="form-group">
                  <label>País</label>
                  <select formControlName="country" class="custom-select">
                    <option *ngFor="let country of countries">
                      {{ country.name }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      f.country.invalid &&
                      (f.country.dirty || f.country.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.country.errors.required"
                      class="form-text text-danger"
                      >O País é necessario</small
                    >
                  </div>
                </div>
-->
                <div class="form-group">
                  <label for="Endereço" translate>ADDRESS</label>
                  <input
                    type="text"
                    name="lol"
                    id="uhuu"
                    class="hidden"
                    autocomplete="off"
                    style="display: none"
                  />
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    formControlName="address"
                    placeholder="{{ 'ADDRESS' | translate }}"
                    name="lol"
                    #search
                  />
                  <div
                    *ngIf="
                      f.address.invalid &&
                      (f.address.dirty || f.address.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.address.errors.required"
                      class="form-text text-danger"
                      translate
                      >ADDRESS-NEEDED</small
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label for="password" translate>PASS</label>
                  <input
                    type="password"
                    class="form-control form-control-lg"
                    formControlName="password"
                    placeholder="{{ 'PASS' | translate }}"
                  />
                  <div
                    *ngIf="
                      f.password.invalid &&
                      (f.password.dirty || f.password.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.password.errors.required"
                      class="form-text text-danger"
                      translate
                      >PASS-NEEDED</small
                    >
                    <small
                      *ngIf="f.password.errors.minlength"
                      class="form-text text-danger"
                      translate
                      >PASS-CHAR</small
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="confirmpassword" translate>CONFIRMPASS</label>
                  <input
                    type="password"
                    class="form-control form-control-lg"
                    formControlName="confirmPassword"
                    placeholder="{{ 'CONFIRMPASS' | translate }}"
                  />
                  <div
                    *ngIf="
                      f.confirmPassword.invalid &&
                      (f.confirmPassword.dirty || f.confirmPassword.touched)
                    "
                    class="alert alert-danger"
                  >
                    <small
                      *ngIf="f.confirmPassword.errors.required"
                      class="form-text text-danger"
                      translate
                      >CONFIRMPASS-NEEDED</small
                    >
                    <small
                      *ngIf="f.confirmPassword.errors.notEquivalent"
                      class="form-text text-danger"
                      translate
                      >CONFIRMPASS-CHAR</small
                    >
                  </div>
                </div>

                <div class="mt-3">
                  <button
                    class="
                      btn btn-block btn-gradient-primary btn-lg
                      font-weight-medium
                      auth-form-btn
                    "
                    type="submit"
                    [disabled]="!registerForm.valid || submitted"
                    translate
                  >
                    {{ submitted ? "SIGNIN.VERIFYING" : "SIGNIN.SIGNIN" }}
                  </button>
                </div>
                <div class="text-center mt-4 font-weight-light">
                  {{ "SIGNIN.HAVEACC" | translate }}
                  <a [routerLink]="['/login']" class="text-primary" translate
                    >SIGNIN.LOGIN</a
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
